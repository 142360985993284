import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import classNames from 'classnames';
import type { ConditionObservers } from 'types/observations';
import ObservedBadge from 'components/ObservedBadge';
import ConditionObservationObservedBy from 'components/ConditionObservationObservedBy';

import styles from './ConditionObservation.module.scss';

interface Props {
  clipPathIds?: {
    barGraphIcon?: string;
  };
  description?: string;
  fullHeight?: boolean;
  iconUrl?: string;
  observedAt: number | null;
  observedBy: ConditionObservers;
  observationValue: string;
  observationValueSuffix?: string;
  size?: 'small' | 'large';
  timezone: string;
  unit: string;
}

export type ConditionObservationProps = Props;

const ConditionObservation: React.FC<Props> = ({
  clipPathIds,
  description,
  fullHeight,
  iconUrl,
  observedAt,
  observedBy,
  observationValue,
  observationValueSuffix,
  size = 'large',
  timezone,
  unit,
}) => {
  const shouldShowObservedBadge = useMemo(() => {
    const observationTypes = ['Forecaster', 'Wind Station', 'Smart Cam'];
    return observationTypes.includes(observedBy);
  }, [observedBy]);

  return (
    <Box
      className={classNames({
        [styles.small]: size === 'small',
        [styles.fullHeight]: fullHeight,
      })}
      data-testid="condition-observation"
    >
      <Box className={styles.content}>
        <Typography component="p" className={styles.observation}>
          <Typography
            variant="title2"
            component="span"
            className={styles.value}
            data-testid="condition-observation-value"
          >
            {observationValue}
          </Typography>
          <Typography
            variant="headline"
            component="span"
            className={styles.unit}
            data-testid="condition-observation-unit"
          >
            {unit}
          </Typography>
          {observationValueSuffix && (
            <Typography
              variant="headline"
              component="span"
              className={styles.observationSuffix}
              data-testid="condition-observation-value-suffix"
            >
              {observationValueSuffix}
            </Typography>
          )}
          {shouldShowObservedBadge && (
            <div className={styles.observedBadge}>
              <ObservedBadge size={size === 'small' ? 'small' : 'medium'} />
            </div>
          )}
        </Typography>
        {description && (
          <Typography
            component="p"
            className={styles.description}
            data-testid="condition-observation-description"
          >
            {description}
          </Typography>
        )}
      </Box>
      <Box className={styles.observedByContainer}>
        <ConditionObservationObservedBy
          clipPathIds={clipPathIds}
          iconUrl={iconUrl}
          observedAt={observedAt}
          observedBy={observedBy}
          timezone={timezone}
        />
      </Box>
    </Box>
  );
};

export default ConditionObservation;
